<script>
    import SSO from '@login/components/SSO.svelte';
    import { translationsStore } from '@stores/translations/translations.js';
</script>

<div class="space-y-10">
    <div class="text-center text-lg font-medium leading-5">
        <p>{$translationsStore.platform_login_title}</p>
        <p class="font-bold">
            {$translationsStore.platform_real_time_tracking}
        </p>
    </div>

    <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
        <SSO isSsoOnly={true} />
        <div class="text-3xs">
            <p class="text-gray-600">
                {$translationsStore.platform_sso_message}
            </p>
        </div>
        <div class="text-3xs">
            <p class="text-gray-600">
                {$translationsStore.platform_general_questions_support}
                <a href="mailto:support@groupcaliber.com" class="font-semibold">
                    {$translationsStore.platform_user_support_contact}
                </a>
            </p>
        </div>
    </div>
</div>
