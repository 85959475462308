<script>
    export let id;
    export let name;
    export let type;
    export let value;
    export let required = false;
    export let autocomplete = null;
    export let errorMessage;

    /**
     * Enable definition of the type attribute from outside the component
     * Normally Svelte doesn't like this, because it needs to bind correct event listeners at compile time, but this
     * circumvents the issue for allowed types.
     * @param node
     */
    function typeAction(node) {
        if (!['text', 'number', 'password', 'email'].includes(type)) {
            throw TypeError(
                "Type prop must be one of 'text', 'number', 'password', 'email'",
            );
        }
        node.type = type;
    }
</script>

<input
    {id}
    {name}
    {required}
    {autocomplete}
    bind:value
    use:typeAction
    class="w-full px-3 py-1 border rounded-md focus:outline-none block"
    class:border-red-500={errorMessage}
/>
