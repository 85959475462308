<script>
    import preloaderPath from '@assets/preloader_grey.gif';

    export let provider;
    let authenticatingWithSSO = false;
    export let id;

    function loginWithSingleSignOn(ssoDriver) {
        authenticatingWithSSO = true;
        window.location.href = `/auth/sso/${ssoDriver}/redirect`;
    }
</script>

<button
    {id}
    type="button"
    class="relative inline-flex w-full items-center justify-center rounded border border-theme-highlight px-2 py-2 text-white {authenticatingWithSSO ? 'opacity-50' : ''} hover:bg-gray-50"
    disabled={authenticatingWithSSO}
    on:click={() => loginWithSingleSignOn(provider)}
>
    <img alt="spinner for when preloading" class="absolute left-1 h-5 w-5" class:opacity-0={!authenticatingWithSSO} src={preloaderPath}/>
    <slot />
</button>
