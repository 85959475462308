<script>
    import FormFields from '@login/shared/FormFields.svelte';
    import Input from '@login/shared/Input.svelte';
    import Label from '@login/shared/Label.svelte';
    import PasswordInput from '@login/shared/PasswordInput.svelte';
    import SSO from '@login/components/SSO.svelte';
    import SubmitButton from '@login/shared/SubmitButton.svelte';
    import { AuthResource } from '@api/resources/AuthResource.js';
    import { translationsStore } from '@stores/translations/translations.js';

    const authResource = new AuthResource();

    const credentials = {};
    const isCapslockEnabled = false;
    let isRedirecting = false;
    let errorMessage = '';

    function login() {
        if (isRedirecting) {
            return;
        }

        isRedirecting = true;
        authResource
            .login(
                credentials.login,
                credentials.password,
                credentials.remember,
            )
            .then(() => {
                // redirect, but keep the query string and hashbang
                window.location.href =
                    '/' + window.location.search + window.location.hash;
            })
            .catch((error) => {
                errorMessage = error.response.data.message;
                isRedirecting = false;
            });
    }
</script>

<div class="text-center text-lg font-medium leading-5">
    <p>{$translationsStore.platform_login_title}</p>
    <p class="font-bold">{$translationsStore.platform_real_time_tracking}</p>
</div>

<div class="p-6 space-y-4 md:space-y-6 sm:p-8">
    <form on:submit|preventDefault={login} class="flex flex-col space-y-6">
        <div class="space-y-2">
            <!-- LOGIN CREDENTIALS SECTION -->
            <FormFields>
                <Label name="login">{$translationsStore.login_username}</Label>
                <Input
                    id="login"
                    type="text"
                    name="login"
                    bind:value={credentials.login}
                    required
                    {errorMessage}
                />
            </FormFields>

            <FormFields>
                <Label name="password">{$translationsStore.login_password}</Label>
                <div class="relative">
                    <PasswordInput
                        id="password"
                        name="password"
                        bind:password={credentials.password}
                        {isCapslockEnabled}
                        {errorMessage}
                    />
                </div>
            </FormFields>
        </div>

        <div class="space-y-8">
            <!-- REMEMBER ME/FORGOT PASSWORD SECTION -->
            <div class="flex items-center justify-between">
                <Label name="remember-me">
                    <input id="remember-me" type="checkbox" class="rounded border-gray-300 text-theme-background shadow-sm focus:ring focus:ring-theme-highlight focus:ring-opacity-50" name="remember" bind:checked={credentials.remember}/>
                    <span class="ml-2 text-1xs">{$translationsStore.platform_remember_me}</span>
                </Label>
                <a href="/forgot-password" class="text-1xs text-caliber-red-300 hover:text-red-500 font-semibold">{$translationsStore.platform_forgot_password}</a>
            </div>

            <div class="relative">
                <SubmitButton id="standard-login-button" {isRedirecting} on:click={login} {errorMessage}>
                    {$translationsStore.platform_login}
                </SubmitButton>
            </div>
        </div>

        <SSO />
    </form>
</div>
