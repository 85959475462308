<script>
    export let disabled = false;
    export let type = 'button';
    export let id;
</script>

<button {id} {disabled} {type} on:click
    class="inline-flex w-full items-center justify-center rounded border border-transparent bg-theme-highlight px-4 py-1 text-center text-theme-highlight-text transition duration-150 ease-in-out hover:opacity-90 focus:opacity-90 focus:outline-none disabled:opacity-25"
>
    <slot />
</button>
