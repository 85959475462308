<script>
    import LogoDotsPattern from '@assets/logo-dots-pattern.svg';
    import LogoWhite from '@assets/logo-white.svg';
    import { translationsStore } from '@stores/translations/translations.js';
</script>

<div class="flex justify-between items-center h-full w-full bg-caliber-navy-900 rounded-l-lg">
    <div class="flex-1">
        <img src="{LogoDotsPattern}" alt="Left dots">
    </div>
    <div class="flex flex-col items-center px-10 my-48">
        <img src="{LogoWhite}" alt="Caliber Logo">
        <p class="italic text-lg text-[#D1D4D8] font-extralight">{$translationsStore.platform_caliber_slogan}</p>
        <div class="mt-2">
            <div class="bg-caliber-red-300 h-1 w-20"></div>
        </div>
    </div>
    <div class="flex-1 flex justify-end">
        <img src="{LogoDotsPattern}" alt="Right dots">
    </div>
</div>
