<script>
    import Icon from '@iconify/svelte';

    export let id;
    export let showPassword;
</script>

<button {id} class="absolute inset-y-0 right-0 flex items-center pr-3" on:click type="button">
    {#if showPassword}
        <Icon icon="clarity:eye-show-line"/>
    {:else}
        <Icon icon="clarity:eye-hide-line"/>
    {/if}
</button>
