<script>
    import AuthCard from '@login/shared/AuthCard.svelte';
    import FormFields from '@login/shared/FormFields.svelte';
    import Input from '@login/shared/Input.svelte';
    import Label from '@login/shared/Label.svelte';
    import SubmitButton from '@login/shared/SubmitButton.svelte';
    import { AuthResource } from '@api/resources/AuthResource.js';
    import { translationsStore } from '@stores/translations/translations.js';

    const authResource = new AuthResource();

    let email = '';
    let isRedirecting = false;
    let errorMessage = '';

    function requestResetPasswordEmail() {
        if (isRedirecting) {
            return;
        }

        isRedirecting = true;
        authResource
            .requestForgottenPassword(email)
            .then((response) => {
                alert(response.data.status);
                window.location = '/login';
            })
            .catch((error) => {
                errorMessage = error.response.data.message;
            })
            .finally(() => {
                isRedirecting = false;
            });
    }
</script>

<AuthCard>
    <div class="mt-14 mb-10 text-lg font-medium leading-6 space-y-4 px-10">
        <p class="text-center text-lg">{$translationsStore.platform_reset_password_title}</p>
        <p class="text-xs">{$translationsStore.platform_reset_password_text}</p>
    </div>

    <form on:submit|preventDefault={requestResetPasswordEmail}>
        <!-- Email Address -->
        <div class="space-y-4 px-10">
            <FormFields>
                <Label name="email">{$translationsStore.platform_email_address}</Label>
                <Input
                    id="email"
                    type="email"
                    name="email"
                    bind:value={email}
                    {errorMessage}
                />
            </FormFields>

            <div class="flex flex-col justify-center space-y-4 text-sm">
                <div class="relative">
                    <SubmitButton id="standard-forgot-button" {isRedirecting} on:click={requestResetPasswordEmail} {errorMessage}>
                        {$translationsStore.platform_reset_link}
                    </SubmitButton>
                </div>
                <p class="text-gray-600 text-2xs">
                    {$translationsStore.platform_back_to}
                    <a href="/login" class="underline font-semibold">
                        {$translationsStore.platform_login_page_text}
                    </a>
                </p>
            </div>
        </div>
    </form>

    <div class="pt-40 text-3xs whitespace-nowrap text-center">
        <p class="text-gray-600">
            {$translationsStore.platform_user_technical_support}
            <a href="mailto:support@groupcaliber.com" class="font-semibold">
                {$translationsStore.platform_user_support_contact}
            </a>
        </p>
    </div>
</AuthCard>
