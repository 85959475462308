<script>
import Button from '@login/shared/Button.svelte';
import preloaderPath from '@assets/preloader_grey.gif';

export let isRedirecting = false;
export let errorMessage;
export let id;
</script>

<Button {id} type="submit" disabled={isRedirecting} on:click>
    <img alt="spinner for when preloading" class="absolute left-2 h-5 w-5" class:opacity-0={!isRedirecting} src={preloaderPath}/>
    <slot />
</Button>
{#if errorMessage}
    <p class="absolute top-full text-xs text-red-500 mt-1">{errorMessage}</p>
{/if}
